import { graphql } from 'gatsby';
import { createGatsbyPageComponent, PostBannerLayout } from '@shapeable/ui';

export default createGatsbyPageComponent('Post', { layout: PostBannerLayout, debug: true });

export const QUERY = graphql`
query PostQuery($id: ID!) {
  platform { post(id: $id) {
    
      
    
        id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
      
      hashPath
      title 
      pretitle
      subtitle
      published
      content { plain text }
      openGraph { title description { plain } image { thumbnails { full { url } } } }
      intro { plain text }
      outro { id text }
      videos {
        id name url
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      imageAssets {
        id name image { id url url2x type }
      }
      organisations {
        id name slug path
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      people {
        id name slug path
        organisation { id name }
        linkedin
        position
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      embeds {
        citations {
          slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        }
        pages {
          id name slug path
          openGraph { title description { plain } image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        people {
          id name slug path
          organisation { id name }
          position
          photo { id url }
        }
        imageAssets {
          id
          slug
          image {
            id url url2x width height type 
          }
        }
      }
    
      banners {
        id name slug title description { id text } alternateText
        image { 
          id url url2x width height type thumbnails { halfBanner { url url2x } mainBanner { url url2x } }
        }
      }
    
  } }
}
`;
